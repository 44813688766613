import logo from './logo.svg';
import './App.css';
const pageText = ['wen lambo', 'wen 100T', 'wagmi'];
const randomPT = pageText[Math.floor(Math.random() * pageText.length)];

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p>
          {randomPT}
        </p>
      </header>
    </div>
  );
}

export default App;
